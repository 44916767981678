const pauseAndReset = (audioElem) => {
	if (!audioElem) {
		return;
	}
	audioElem.pause();
	audioElem.currentTime = 0;
};

const playPause = (audioElem, otherAudioElemsObject = {}) => {

	var isPlaying = audioElem.currentTime > 0 && !audioElem.paused && !audioElem.ended 
	&& audioElem.readyState > audioElem.HAVE_CURRENT_DATA;
	
	if (audioElem.paused) {
		Object.keys(otherAudioElemsObject).forEach((key) => {
			pauseAndReset(otherAudioElemsObject[key]);
		});

		if (!isPlaying) {
			audioElem.play().catch((e)=>{
					  console.log('something went wrong in playPause',e);
				  });
		  }
	} else {
		pauseAndReset(audioElem);
	}
};

export default playPause;
